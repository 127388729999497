<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <!-- <v-col cols="12">
          <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
            {{ $t('ticket.Subject Tickets') }}
          </h5>
        </v-col> -->
        <!-- subjects list -->
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("ticket.Subject Tickets")
                }}</v-toolbar-title>
                <button
                  class="py-5 mb-0 ms-auto"
                  v-if="
                    $store.getters['auth/str_per'].indexOf(
                      'ticket-subject-create'
                    ) > -1
                  "
                  @click="add_subject()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_subjects"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                :loading="loading"
                :headers="header"
                :items="subjects"
                :paginate="paginate"
                @action="action"
                :btn_table="btn_table"
                :btns="btns"
                :btn_loading="btn_loading"
                @get_data_paginate="get_data_paginate"
                @set_option="set_option"
                @change_pre_page="ChangePrePage"
                ref="table"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import FormFilter from "../Components/Filter.vue";
import Table from "../Components/PaginationTable.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("ticket");
export default {
  name: "subject-Page",
  components: {
    Table,
    Notify,
    Modal,
    FormFilter,
  },
  data() {
    return {
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
      filters: [],
      options: {},
      btn_table: {
        excel: false,
        print: false,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "ticket-subject-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "ticket-subject-delete",
        },
      ],

      header: [
        {
          text: this.$i18n.t("ticket.subject"),
          value: "subject",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.Admin Groups"),
          value: "subject_group.name",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.Admin"),
          // value: "admins.users.name",
          value: "admins.users.user",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      list_of_admins: [],
      list_of_groups_admin: [],
      form_data: {
        ticket_admin_group_id: "",
        admin_id: "",
        subject: "",
      },
      form_style: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("ticket.subject"),
          error: null,
          value_text: "subject",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "autocomplete",
          type_select: "single",
          label: this.$i18n.t("ticket.Admin Groups"),
          value_text: "ticket_admin_group_id",
          items: [],
          error: null,
        },
        {
          col: "12",
          type: "autocomplete",
          type_select: "single",
          label: this.$i18n.t("ticket.Admin"),
          value_text: "admin_id",
          items: [],
          error: null,
          // title_select: "users.name",
          title_select: "users.user",
        },
      ],
    };
  },
  computed: {
    ...mapState(["subjects"]),
  },
  methods: {
    ...mapActions(["getTicketSubject", "destroyTicketSubject"]),
    set_option(val) {
      this.options = val;
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.get_subjects();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_subjects(false, false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_subjects(true);
    },
    get_subjects(reset = false, first = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      if (first) {
        formData.append("first", first);
      }
      this.getTicketSubject({ page: this.paginate.page, data: formData }).then(
        (response) => {
          // console.log(response);
          if (first) {
            this.list_of_admins = response.data.admins;
            this.list_of_groups_admin = response.data.groups;
          }
          this.loading = false;
          this.paginate.total = response.data.subjects.total;
          this.paginate.itemsPerPage = parseInt(
            response.data.subjects.per_page
          );

          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.reset_option = true;
            this.$refs.table.resetOption();
          }
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit(item);
      } else if (name == "destroy") {
        this.destroy(id);
      }
    },
    add_subject() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("ticket.New Subject");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[1].items = this.list_of_groups_admin;
      this.$store.state.form.style_form[2].items = this.list_of_admins;
      this.$store.commit("form/INFO_CALL", {
        name: "ticket/addTicketSubject",
      });
    },
    edit(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("ticket.Edit Subject") + " # " + item.subject;
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[1].items = this.list_of_groups_admin;
      this.$store.state.form.style_form[2].items = this.list_of_admins;
      this.form_data.subject = item.subject;
      this.form_data.ticket_admin_group_id = parseInt(
        item.ticket_admin_group_id
      );
      this.form_data.admin_id = parseInt(item.admin_id);
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "ticket/editTicketSubject",
        id: item.id,
      });
    },
    destroy(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyTicketSubject(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              // console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_subjects(true, true);
    document.title = this.$i18n.t("ticket.Subject Tickets");
  },
};
</script>
